<template>
  <b-card-code>
    <div class="d-flex justify-content-center mb-1" v-if="loading">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-if="!loading">
      <b-table
        responsive
        id="scheduleTable"
        :fields="schedule_fields"
        :items="schedule_items"
        :per-page="schedule_perPage"
        hover
        v-if="schedule_items.length"
      >
        <template #cell(schedule_id)="data">
          SC-{{ data.item.schedule_id }}
        </template>
        <template #cell(last_run_at)="data">
          {{ data.item.last_run_at | formatdate }}
        </template>
        <template #cell(scan_scheduled_day)="data">
          <span v-if="data.item.scan_scheduled_day">
            {{ data.item.scan_scheduled_day }}
            <span
              v-if="
                data.item.scan_schedule_time != null &&
                data.item.timezone != null
              "
            >
              {{
                convertTimeToTimezone(
                  data.item.scan_schedule_time,
                  data.item.timezone
                )
              }}
            </span>
          </span>
          <span v-else>
            {{ data.item.scan_schedule_date | formatdate }}
            <!-- {{ convertTimeToTimezone(data.item.scan_schedule_time, data.item.timezone) }} -->
          </span>
          <!-- {{ data.item.scan_scheduled_day | formatdate }} -->
        </template>
        <template #cell(scan_schedule_type)="data">
          <b-badge variant="warning" v-if="data.item.scan_schedule_type == 5"
            >Monthly</b-badge
          >
          <b-badge variant="info" v-else-if="data.item.scan_schedule_type == 6"
            >Quarterly
          </b-badge>

          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 7"
            >Once in six-Months</b-badge
          >
          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 8"
            >Once in a year
          </b-badge>
          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 1"
            >Daily
          </b-badge>
          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 2"
            >Weekly
          </b-badge>
          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 3"
            >Weekends
          </b-badge>
          <b-badge
            variant="success"
            v-else-if="data.item.scan_schedule_type == 4"
            >Continuous
          </b-badge>

          <b-badge variant="danger" v-else>NA</b-badge>
        </template>
        <template #cell(is_paused)="data">
          <div class="d-flex justify-content-start">
            <b-badge variant="light-success" v-if="data.item.is_paused == 0"
              >Active</b-badge
            >
            <b-badge variant="light-danger" v-else>Inactive</b-badge>
          </div>
          <b-badge
            variant="danger"
            v-if="data.item.is_paused == 0"
            style="cursor: pointer"
            title="Click to pause this schedule."
            @click="changeSchedule(data.item.schedule_id, 1)"
            >Make Inactive</b-badge
          >
          <b-badge
            variant="success"
            v-if="data.item.is_paused == 1"
            style="cursor: pointer"
            title="Click to resume this schedule."
            @click="changeSchedule(data.item.schedule_id, 0)"
            >Make Active</b-badge
          >
        </template>
        <template #cell(actions)="data">
          <button
            @click="deleteShedule(data.item.schedule_id)"
            type="button"
            class="btn btn-danger btn-sm mr-1"
            style="position: relative"
          >
            Delete
          </button>
        </template>
      </b-table>
      <div v-else>
        <div
          class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
        >
          <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
          <h3 class="font-weight-bolder">No Data Found</h3>
        </div>
      </div>
    </div>
    <!-- <b-pagination
      v-model="schedule_currentPage"
      :numberOfPages="schedule_total"
      :total-rows="schedule_rows"
      :link-gen="linkGen"
      :per-page="schedule_perPage"
      :align="pagination_pos"
      aria-controls="scheduleTable"
      use-router
    /> -->
    <b-pagination-nav
      v-model="schedule_currentPage"
      :numberOfPages="schedule_total"
      :total-rows="schedule_rows"
      :link-gen="linkGen"
      :per-page="schedule_perPage"
      :align="pagination_pos"
      aria-controls="scheduleTable"
      use-router
    />
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BCard,
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BBadge,
  BSidebar,
  BFormGroup,
  VBToggle,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import AssetsDiscover from "../AssetDiscovery/components/assets_discovered.vue";
// import moment from "moment";
import moment from "moment-timezone";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    VBToggle,
    BFormInput,
    BFormSelect,
    BSpinner,
    BButton,
    BBadge,
    // AssetsDiscover,
    BSidebar,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
    vSelect,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      userId: localStorage.getItem("userid"),
      // Call orgaization API and return in this format
      scan_id: 0,
      selected_group: 0,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      fields: [
        { key: "index", label: "#" },
        { key: "domain", label: "Domain" },
        // { key: "organization.org_name", label: "Organization" },
        { key: "scan_status", label: "Status" },
        { key: "errors", label: "Errors" },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      //  organization: this.$store.state.app.org_id,
      organization: null,
      // scheduleorganization: this.$store.state.app.org_id,
      domain_organization: null,
      organizations: [],
      name_filter: "",
      domain: "",
      loading: false,
      schedule_selected: null,
      schedule_data: [
        { value: null, text: "--Select Schedule--" },
        { value: 5, text: "Once in a month" },
        { value: 6, text: "Once in a three-month" },
        { value: 7, text: "Once in a six-month" },
        { value: 8, text: "Once in a year" },
      ],

      // schedule table config
      schedule_items: [],
      schedule_currentPage: 1,
      schedule_perPage: 10,
      schedule_rows: 0,
      schedule_total: 1,
      schedule_fields: [
        { key: "schedule_id", label: "Schedule Id" },
        { key: "assetgroup.group_name", label: "Asset Group Name" },
        { key: "agenttype.agent_type_nicename", label: "Type" },
        { key: "last_run_at", label: "Last Run At" },
        { key: "scan_scheduled_day", label: "Scheduled Time" },
        { key: "timezone", label: "Timezone" },
        { key: "is_paused", label: "Status" },
        { key: "scan_schedule_type", label: "Schedule" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  props: {
    scheduleorganization: {
      type: Number,
      required: true,
    },
    tabIndexSchedule: {
      type: Number,
      required: true,
    },
  },
  watch: {
    schedule_currentPage: function (newVal, oldVal) {
      this.SchedulesearchFn(false);
    },
    tabIndexSchedule: function (newVal, oldVal) {
      if (newVal === 1) {
        this.loadSchedules();
      }
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    if (this.tabIndexSchedule === 1) {
      this.loadOrg();
      this.loadSchedules();
      //   this.SchedulesearchFn();
    }
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY");
      }
    },
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    convertTimeToTimezone(time, timezone) {
      const timeFormat = "HH:mm A";
      return moment.utc(time, timeFormat).tz(timezone).format(timeFormat);
    },
    loadOrg: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
    },
    loadSchedules: function () {
      this.onVerticalCollapse();
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const s_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule/asset-group-scans?is_schedule=0",
      };
      this.$http(s_options).then((res) => {
        this.schedule_items = res.data.results;
        this.schedule_total = Math.ceil(res.data.count / this.schedule_perPage);
        this.schedule_rows = res.data.results.length;
      });
    },

    deleteShedule(id) {
      let data = {
        schedule_id: id,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule/delete-asset-group-schedule/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data) {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        self.SchedulesearchFn();
      });
    },
    SchedulesearchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.is_search_on = false;
      this.loading = true;
      this.filter = [];
      if (reset) this.schedule_currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "scan/schedule/asset-group-scans?is_schedule=0";

      if (this.scheduleorganization != null) {
        this.filter.push({ organization: this.scheduleorganization });
        url = url + "&org_id=" + this.scheduleorganization;
      }
      if (this.filter.length) this.is_search_on = true;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.schedule_items = res.data.results;
        this.schedule_rows = res.data.results.length;
        this.schedule_total = Math.ceil(res.data.count / this.schedule_perPage);
        this.loading = false;
      });
    },
    assets_discovered: function (id) {
      this.scan_id = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },

    changeSchedule: function (id, value) {
      let data = {
        schedule_id: id,
        is_paused: value,
      };
      const s_options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL + "scan/schedule/toggle-schedule-status/",
      };
      var self = this;
      this.$http(s_options).then((res) => {
        // console.log("scans", res.data);
        self.SchedulesearchFn();
      });
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
.bg-dark-blue {
  /* background-color: #171153 !important; */
  background-color: #1a457b1f !important;
  /* background-color: #1e176c !important; */
}

[dir] .dark-layout .bg-dark-blue {
  background-color: #04002f !important;
  color: #fff;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
