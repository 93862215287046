<template>
  <div>
    <b-tabs v-model="tabIndexSchedule">
      <b-tab title="Asset Schedules">
        <b-card-code title="">
          <b-card-code class="bg-light-secondary mb-1" title="Search">
            <b-form inline style="display: inline-block; width: 100%">
              <div class="row" style="width: 100%; padding: 5px">
                <v-select
                  id="asset_filter"
                  class="ml-1"
                  style="width: 55%; background-color: white"
                  v-model="asset_filter"
                  label="text"
                  :options="asset_filter_options"
                  @search="fetchOptions"
                  placeholder="Asset Name or Nickname"
                  autocomplete
                  @input="searchFn"
                />
                <!-- {{asset_filter_options}} -->
                <b-form-select
                  style="width: 28%"
                  class="ml-1"
                  @change="searchFn"
                  v-model="agent_type"
                  :options="agent_types"
                >
                </b-form-select>
                <!-- {{agent_types}} -->

                <b-button
                  style="width: 12%"
                  class="ml-auto"
                  variant="primary"
                  @click="searchFn"
                >
                  Search
                </b-button>
              </div>
            </b-form>
          </b-card-code>
          <!-- <div class="app-fixed-search d-flex align-items-center"> -->
          <div class="d-flex justify-content-center mb-1" v-if="loading">
            <b-spinner class="float-right" label="Floated Right" />
          </div>
          <!-- </div> -->
          <div v-if="!loading">
            <b-table
              responsive
              id="allscansTable"
              :fields="fields"
              :items="items"
              :per-page="perPage"
              ref="table"
              v-if="items.length"
              hover
            >
              <template v-slot:head(actions)>
                <div
                  class="d-flex justify-content-between"
                  style="white-space: nowrap; overflow: hidden"
                >
                  <span style="text-overflow: ellipsis">Action</span>
                </div>
              </template>
              <template v-slot:head(scan_scheduled_day)>
                <div
                  class="d-flex justify-content-between"
                  style="white-space: nowrap; overflow: hidden"
                >
                  <span style="text-overflow: ellipsis">Scheduled Time</span>
                </div>
              </template>
              <template v-slot:head(last_run_at)>
                <div
                  class="d-flex justify-content-between"
                  style="white-space: nowrap; overflow: hidden"
                >
                  <span style="text-overflow: ellipsis">Last Run At</span>
                </div>
              </template>
              <template v-slot:head(scan_input_meta)>
                <div
                  class="d-flex justify-content-between"
                  style="white-space: nowrap; overflow: hidden"
                >
                  <span style="text-overflow: ellipsis">Scan Info</span>
                </div>
              </template>
              <template #cell(schedule_id)="data">
                SC-{{ data.item.schedule_id }}
              </template>
              <template #cell(asset_name)="data">
                <div class="primary" v-if="data.item.asset != null">
                  <router-link
                    class="text-truncate"
                    :to="'/assets/scan/' + data.item.asset.asset_id"
                    >{{ data.item.asset.asset_nickname }} ({{
                      data.item.asset.asset_name
                    }})</router-link
                  >
                </div>
                <div class="primary" v-if="data.item.assetgroup != null">
                  <router-link
                    :to="'/assets/group/' + data.item.asset_group + '/assets'"
                    >{{ data.item.assetgroup.group_name }} (Asset
                    Group)</router-link
                  >
                </div>
              </template>

              <template #cell(last_run_at)="data">
                {{ data.item.last_run_at | formatdate }}
              </template>
              <template #cell(scan_scheduled_day)="data">
                <span v-if="data.item.scan_scheduled_day">
                  {{ data.item.scan_scheduled_day }}
                  <span
                    v-if="
                      data.item.scan_schedule_time != null &&
                      data.item.timezone != null
                    "
                  >
                    {{
                      convertTimeToTimezone(
                        data.item.scan_schedule_time,
                        data.item.timezone
                      )
                    }}
                  </span>
                </span>
                <span v-else>
                  {{ data.item.scan_schedule_date | formatdate }}
                  <span
                    v-if="
                      data.item.scan_schedule_time != null &&
                      data.item.timezone != null
                    "
                  >
                    {{
                      convertTimeToTimezone(
                        data.item.scan_schedule_time,
                        data.item.timezone
                      )
                    }}
                  </span>
                </span>
                <!-- {{ data.item.scan_scheduled_day | formatdate }} -->
              </template>
              <template #cell(scan_schedule_type)="data">
                <b-badge
                  variant="primary"
                  v-if="data.item.scan_schedule_type == 1"
                  >Daily</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="data.item.scan_schedule_type == 2"
                  >Weekly</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 3"
                  >Weekends</b-badge
                >
                <b-badge
                  variant="success"
                  v-else-if="data.item.scan_schedule_type == 4"
                  >Continous</b-badge
                >
                <b-badge
                  variant="warning"
                  v-else-if="data.item.scan_schedule_type == 5"
                  >Monthly</b-badge
                >
                <b-badge
                  variant="info"
                  v-else-if="data.item.scan_schedule_type == 6"
                  >Quarterly</b-badge
                >
                <b-badge variant="danger" v-else>NA</b-badge>
              </template>
              <template #cell(scan_input_meta)="data">
                <b-badge
                  variant="danger"
                  v-if="
                    data.item.agenttype &&
                    data.item.agenttype.agent_type_code == 'masscan'
                  "
                  >{{ data.item.scan_input_meta.type }}</b-badge
                >
              </template>
              <template #cell(is_paused)="data">
                <div class="d-flex justify-content-start">
                  <b-badge
                    variant="light-success"
                    v-if="data.item.is_paused == 0"
                    >Active</b-badge
                  >
                  <b-badge variant="light-danger" v-else>Inactive</b-badge>
                </div>
                <b-badge
                  variant="danger"
                  v-if="data.item.is_paused == 0"
                  style="cursor: pointer"
                  title="Click to pause this schedule."
                  @click="changeSchedule(data.item.schedule_id, true)"
                  >Make Inactive</b-badge
                >
                <b-badge
                  variant="success"
                  v-if="data.item.is_paused == 1"
                  style="cursor: pointer"
                  title="Click to resume this schedule."
                  @click="changeSchedule(data.item.schedule_id, false)"
                  >Make Active</b-badge
                >
              </template>
              <template #cell(actions)="data">
                <button
                  @click="gotoDelete(data.item.schedule_id)"
                  type="button"
                  class="btn btn-danger btn-sm mr-1"
                  style="position: relative"
                >
                  Delete
                </button>
              </template>
            </b-table>
            <div v-else>
              <div
                class="w-100 d-flex flex-column align-items-center justify-content-center py-2"
              >
                <feather-icon icon="DatabaseIcon" size="34" class="mb-50" />
                <h3 class="font-weight-bolder">No Data Found</h3>
              </div>
            </div>
          </div>

          <b-pagination-nav
            v-model="currentPage"
            :numberOfPages="total"
            :total-rows="rows"
            :link-gen="linkGen"
            :per-page="perPage"
            :align="pagination_pos"
            aria-controls="allscansTable"
            use-router
          />
        </b-card-code>
      </b-tab>
      <b-tab title="Asset Group">
        <AssetGroupSchedule :tabIndexSchedule="tabIndexSchedule" />
      </b-tab>
      <b-tab title="Asset Discovery">
        <AssetDiscoverySchedule
          :scheduleorganization="organization"
          :agent_types="full_agent_types"
        />
      </b-tab>
      <b-tab title="Domain Discovery">
        <DomainSchedule :scheduleorganization="organization" />
      </b-tab>
      <!-- <b-tab title="Attack Surface">
        <AttackSurfaceSchedule :scheduleorganization="organization"/>
      </b-tab> -->
    </b-tabs>
    <!-- Right Sidebar starts -->
    <!-- <b-sidebar
            id="sidebar-task-handler"
            sidebar-class="sidebar-lg"
            bg-variant="white"
            shadow
            backdrop
            no-header
            right
        >



            <ScanDetail
            :scan_details="scan_details"
            :closeSidebar="closeSidebar"
            />


        </b-sidebar> -->
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text> Do you really want to delete ? </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteShedule()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
  VBToggle,
  BSidebar,
  BRow,
  BForm,
  BFormInput,
  BFormSelect,
  BSpinner,
  BButton,
  BTab,
  BTabs,
} from "bootstrap-vue";
// import moment from "moment";
import moment from "moment-timezone";
// import ScanDetail from './components/details.vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DomainSchedule from "./domainSchedule.vue";
import AssetDiscoverySchedule from "./massSchedule.vue";
import AttackSurfaceSchedule from "./attackSurfaceSchedule.vue";
import AssetGroupSchedule from "./assetGroupSchedule.vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    VBToggle,
    BSidebar,
    // ScanDetail,
    BRow,
    BForm,
    BFormInput,
    BFormSelect,
    vSelect,
    BSpinner,
    BButton,
    BTab,
    BTabs,
    DomainSchedule,
    AssetDiscoverySchedule,
    AttackSurfaceSchedule,
    AssetGroupSchedule,
  },
  data() {
    return {
      // Call orgaization API and return in this format
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      scan_details: [],
      asset_filter: null,
      asset_filter_options: [{ value: null, text: "--Select Asset--" }],
      status_filter: null,
      status_filter_options: [
        { value: null, text: "--Select Type--" },
        { value: 1, text: "Daily" },
        { value: 2, text: "Weekly" },
        { value: 3, text: "Weekends" },
        { value: 4, text: "Continuous" },
        { value: 5, text: "Monthly" },
        { value: 6, text: "Quarterly" },
      ],
      fields: [
        { key: "schedule_id", label: "#" },
        { key: "asset_name", label: "Asset" },
        { key: "agenttype.agent_type_nicename", label: "Type" },
        // { key: "scan_input_meta", label: "Scan Info" },
        { key: "last_run_at", label: "Last Run At" },
        { key: "scan_scheduled_day", label: "Scheduled Time" },
        { key: "timezone", label: "Timezone" },
        { key: "is_paused", label: "Status" },
        // { key: 'is_scan_scheduled', label: 'Status' },
        { key: "scan_schedule_type", label: "Schedule" },
        //date_time = now.strftime("%m/%d/%Y, %H:%M:%S")
        //moment(String(value)).format('MM/DD/YYYY hh:mm')
        // { key: 'schedule.p', label: 'Last Run' },
        // { key: "last_run_at", label: "Last Run" },
        // { key: 'scan_result_meta', label: 'Result Meta' },
        { key: "actions", label: "Actions" },
      ],
      is_staff: this.$store.state.app.user.is_staff,
      organization: this.$store.state.app.org_id,
      // organization: !this.$store.state.app.user.is_staff
      //   ? this.$store.state.app.user.profile.organization
      //   : this.$store.state.app.org_id,
      // organization: null,
      organizations: [],
      loading: false,
      agent_type: null,
      agent_types: [{ value: null, text: "--Select Agent Type--" }],
      openDeleteModal: false,
      schedule_id: "",
      full_agent_types: [],
      agentTypeFullResult: [],
      tabIndexSchedule: 0,
    };
  },
  directives: {
    "b-toggle": VBToggle,
  },
  filters: {
    formatdate: function (value) {
      if (value) {
        return moment(String(value)).format("DD-MMM-YY");
      }
    },
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.load(false);
    },
    tabIndexSchedule: function (newVal, oldVal) {
      this.$router.replace({ query: null });
    },
  },
  mounted() {
    this.onVerticalCollapse();
  },
  created: function () {
    if (this.organization != null) {
      this.load();
      this.loadOrg();
      this.loadAgentTypes();
      this.fetchOptions();
      this.searchFn();
    }
  },
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },
    convertTimeToTimezone(time, timezone) {
      const timeFormat = "HH:mm A";
      return moment.utc(time, timeFormat).tz(timezone).format(timeFormat);
    },
    loadOrg: function () {
      if (this.is_staff) {
        this.loading = true;
        // Load oragnization drop down
        const o_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "organization/organization/get-all-org",
        };
        var self = this;
        this.$http(o_options).then((res) => {
          // console.log(res.data)
          res.data.map(function (value, key) {
            let org = {
              value: res.data[key].org_id,
              text: res.data[key].org_name,
            };
            // console.log(res.data[key].profile.organization)
            self.organizations.push(org);
          });
          this.loading = false;
        });
      } else {
        this.organization = this.$store.state.app.org_id;
        this.searchFn();
      }
    },
    load: function () {
      this.onVerticalCollapse();
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule/?query=true" +
          "&page=" +
          this.currentPage +
          "&org_id=" +
          this.organization,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.results.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
        // this.rows = res.data.length;
      });
    },
    gotoAsset(id) {
      this.$router.push({ name: "Scan Asset", params: { id: id } });
    },
    getDetails(id) {
      // this.$router.push({name: 'Edit Scanner', params: { id: id}});
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/details?id=" + id,
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        self.scan_details = res.data;
        //this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
    },
    changeSchedule: function (id, value) {
      let data = {
        is_paused: value,
      };
      const s_options = {
        method: "PATCH",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "scan/schedule/" + id + "/",
      };
      var self = this;
      this.$http(s_options).then((res) => {
        console.log("scans", res.data);
        self.searchFn();
      });
    },
    gotoDelete(schedule_id) {
      this.openDeleteModal = true;
      this.schedule_id = schedule_id;
    },
    deleteShedule() {
      // if (confirm("Do you really want to delete the scan?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "scan/schedule/" +
          this.schedule_id +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data == "") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Successfully deleted",
              icon: "TrashIcon",
              variant: "success",
            },
          });
          this.openDeleteModal = false;
          this.load();
          // this.searchFn();
        }
      });
      // }
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-task-handler");
    },
    loadAgentTypes: function () {
      const a_options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "agent/type",
      };
      var self = this;
      this.$http(a_options).then((res) => {
        // agent type full result
        this.agentTypeFullResult = res.data;
        this.agentTypeFullResult.map((value) => {
          let atype = {
            agent_type_id: value.agent_type_id,
            agent_type_code: value.agent_type_code,
          };
          this.full_agent_types.push(atype);
        });
        res.data.map(function (value, key) {
          if (
            res.data[key].agent_type_code !== "amass" &&
            res.data[key].agent_type_code !== "emailharvester" &&
            res.data[key].agent_type_code !== "hibp" &&
            res.data[key].agent_type_code !== "cidr" &&
            res.data[key].agent_type_code !== "prowler"
          ) {
            let type = {
              value: res.data[key].agent_type_id,
              text: res.data[key].agent_type_nicename,
            };
            // console.log(res.data[key].profile.organization)
            self.agent_types.push(type);
          }
        });
      });
    },
    searchFn: function (reset = true) {
      this.onVerticalCollapse();
      this.loading = true;
      this.filter = [];
      if (reset) this.currentPage = 1;

      let url =
        process.env.VUE_APP_BASEURL +
        "scan/schedule?query=true" +
        "&page=" +
        this.currentPage;

      // var url = process.env.VUE_APP_BASEURL + "scan/schedule";
      // url = url + "?query=true";

      if (this.organization != null) {
        this.filter.push({ organization: this.organization });
        url = url + "&org_id=" + this.organization;
      }
      if (this.agent_type != null) {
        this.filter.push({ agent_type: this.agent_type });
        url = url + "&agent_type=" + this.agent_type;
      }
      if (this.asset_filter != null) {
        url = url + "&asset_id=" + this.asset_filter.value;
      }

      if (this.status_filter != null) url = url + "&type=" + this.status_filter;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        res.data.results.map(function (value, key) {
          res.data.results[key].scan_input_meta = JSON.parse(
            res.data.results[key].scan_input_meta
          );
        });
        console.log(res.data.results, "details group");
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    //----------- fetch asset list data

    fetchOptions(search) {
      if (search.length > 2) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/org-asset?org_id=" +
            this.organization +
            "&name=" +
            search,
        };
        var self = this;

        this.$http(a_options).then((res) => {
          self.asset_filter_options = [];
          // self.asset_filter_options.push({ value: null, text: "--Searching for "+search });
          res.data.results.map(function (value, key) {
            let a = {
              value: res.data.results[key].asset_id,
              text:
                res.data.results[key].asset_nickname +
                " (" +
                res.data.results[key].asset_name +
                ")",
            };
            self.asset_filter_options.push(a);
          });
        });
      }
    },

    // previous asset list

    // fetchOptions: function (search, loading) {
    //   // if (search.length > 3) {
    //   this.asset_filter_options = [];
    //   // loading(true);

    //   this.loading = true;
    //   // Load assets here
    //   const a_options = {
    //     method: "GET",
    //     headers: { "content-type": "application/json" },
    //     url:
    //       process.env.VUE_APP_BASEURL +
    //       "asset/org-asset?name=" +
    //       search +
    //       "&org_id=" +
    //       this.organization,
    //   };
    //   var self = this;
    //   this.$http(a_options).then((res) => {
    //     self.asset_options = [];
    //     res.data.map(function (value, key) {
    //       let a = {
    //         value: res.data[key].asset_id,
    //         text:
    //           res.data[key].asset_nickname +
    //           " (" +
    //           res.data[key].assetType.asset_type_name +
    //           ")",
    //       };
    //       // console.log(res.data[key].profile.organization)
    //       self.asset_filter_options.push(a);
    //       self.loading = false;
    //       // loading(false);
    //     });
    //   });
    //   // }
    // },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="css">
.w-60 {
  width: 60% !important;
}
.w-40 {
  width: 40% !important;
}
</style>